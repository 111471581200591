export const PANORAMA = {
  options: {
    name: "interior-aura",
    items: [
      {
        src: require("@/assets/img/cars/aura/interior/light-q75.webp"),
        srcMobile: require("@/assets/img/cars/aura/interior/mobile/light-q25.webp"),
        startLookAt: 270,
      },
      {
        src: require("@/assets/img/cars/aura/interior/dark-q75.webp"),
        srcMobile: require("@/assets/img/cars/aura/interior/mobile/dark-q25.webp"),
        startLookAt: 270,
      },
    ],
  },
  infospots: [
    {
      position: {
        x: 3000,
        y: -4000,
        z: -2000,
      },
      title: "Отделка салона натуральной кожей",
      desc: "Показывает принадлежность к представительскому классу.",
      media: { picture: require("@/assets/img/cars/aura/media/11.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: 5000,
        y: -1800,
        z: 1300,
      },
      title: "Двухцветное рулевое колесо",
      desc: "Стильный вид и удобство управления.",
      media: { picture: require("@/assets/img/cars/aura/media/12.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: 5000,
        y: -2300,
        z: -2000,
      },
      title: "Вариативная отделка и расцветка салона",
      desc: "Дизайн интерьера под личные вкусы.",
      media: { picture: require("@/assets/img/cars/aura/media/13.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: 3500,
        y: -2700,
        z: 800,
      },
      title: "Металлические накладки на педали",
      desc: "Стильный атрибут динамичного автомобиля.",
      media: { picture: require("@/assets/img/cars/aura/media/14.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: 1500,
        y: -3800,
        z: -3000,
      },
      title: "Металлические накладки на пороги с названием модели",
      desc: "Дополнительный штрих к особенному исполнению.",
      media: { picture: require("@/assets/img/cars/aura/media/15.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: -3000,
        y: -3000,
        z: 0,
      },
      title: "Максимальный простор на заднем ряду",
      desc: "Благодаря рекордной в классе колесной базе.",
      media: { picture: require("@/assets/img/cars/aura/media/16.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: -5000,
        y: -1800,
        z: -1900,
      },
      title: "Подогрев спинки и подушки задних кресел",
      desc: "Обеспечивает комфорт зимой и в межсезонье.",
      media: { picture: require("@/assets/img/cars/aura/media/17.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: -5000,
        y: -1500,
        z: 0,
      },
      title: "Задний подлокотник с подстаканниками",
      desc: "Повышает удобство дальних и ближних поездок.",
      media: { picture: require("@/assets/img/cars/aura/media/18.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: -5000,
        y: -500,
        z: 1800,
      },
      title: "Новая форма подголовников заднего сиденья",
      desc: "Помогает отдохнуть в дороге в комфортных условиях.",
      media: { picture: require("@/assets/img/cars/aura/media/19.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: -3800,
        y: -800,
        z: 3000,
      },
      title: "Улучшенный пакет шумоизоляции",
      desc: "Снижает высокочастотные шумы.",
      media: { picture: require("@/assets/img/cars/aura/media/20.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: 5000,
        y: -400,
        z: -1500,
      },
      title: "Обогрев ветрового стекла и форсунок омывателя",
      desc: "Обеспечивает отличную видимость в зимних условиях.",
      media: { picture: require("@/assets/img/cars/aura/media/21.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: 3500,
        y: -2300,
        z: 2500,
      },
      title: "Обогрев рулевого колеса",
      desc: "Комфорт движения с первых секунд.",
      media: { picture: require("@/assets/img/cars/aura/media/22.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: 5000,
        y: -1800,
        z: -650,
      },
      title:
        "Мультимедиа LADA EnjoY Vision Pro с онлайн-сервисами Яндекс Авто и большим 10-дюймовым экраном",
      desc: "Автономный Интернет и качественный звук.",
      media: { picture: require("@/assets/img/cars/aura/media/23.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: 5000,
        y: -1800,
        z: 1800,
      },
      title: "10” цифровая комбинация приборов",
      desc: "Полный набор информации и отображение карты навигатора в максимально удобном месте.",
      media: { picture: require("@/assets/img/cars/aura/media/24.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
    {
      position: {
        x: 5000,
        y: 600,
        z: 0,
      },
      title: "Датчики дождя и света",
      desc: "Помощь водителю при меняющихся условиях движения.",
      media: { picture: require("@/assets/img/cars/aura/media/25.webp") },
      gtmReachGoal: "aura-cross_interior_pin-doors",
    },
  ],
};

export const GALLERY = {
  folder: "aura/exterior",
  colorActive: 0,
  colors: [
    {
      title: 'ЧЕРНЫЙ "ПАНТЕРА" (672)',
      hex: "#151314",
      folder: "black",
      fileName: {
        light: "AURAblack_day00",
      },
    },
    {
      title: 'ЗОЛОТИСТО-КОРИЧНЕВЫЙ "КОРИАНДР" (790)',
      hex: "#6F6C63",
      folder: "brown",
      fileName: {
        light: "AURAbrown_day00",
      },
    },
    {
      title: 'СЕРЕБРИСТЫЙ "ПЛАТИНА" (691)',
      hex: "#BABBB8",
      folder: "platina",
      fileName: {
        light: "AURAplatina_day00",
      },
    },
    {
      title: 'БЕЛЫЙ "ЛЕДНИКОВЫЙ" (221)',
      hex: "#C3C5C4",
      folder: "ice",
      fileName: {
        light: "AURAice_day00",
      },
    },
  ],
  breakpoints: [
    {
      slideIndex: 20, // 10
      items: [
        {
          offset: {
            top: "6vw",
            left: "0vw",
          },
          offsetMobile: {
            top: "-3",
            left: "-3",
          },
          title: "Крупные 17’ литые диски",
          desc: "Классического премиального стиля с алмазной шлифовкой.",
          media: {
            picture: require("@/assets/img/cars/aura/media/1.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "1vw",
            left: "-14vw",
          },
          offsetMobile: {
            top: "-1",
            left: "3",
          },
          title: "Широкий дверной проём",
          desc: "Значительно увеличенное пространство для пассажиров заднего ряда.",
          media: {
            picture: require("@/assets/img/cars/aura/media/2.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "2vw",
            left: "10vw",
          },
          offsetMobile: {
            top: "-6",
            left: "13",
          },
          title: "Светодиодная оптика",
          desc: "Надёжно освещает дорогу в любых условиях.",
          media: {
            picture: require("@/assets/img/cars/aura/media/3.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-2vw",
            left: "-5vw",
          },
          offsetMobile: {
            top: "-10",
            left: "-8",
          },
          title:
            "Наружные зеркала заднего вида с электроприводом складывания и обогревом",
          desc: "Удобны в стеснённых городских условиях.",
          media: {
            picture: require("@/assets/img/cars/aura/media/4.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "7vw",
            left: "8vw",
          },
          offsetMobile: {
            top: "-15",
            left: "-6",
          },
          title: "Светодиодные противотуманные фары",
          desc: "Улучшенная обзорность плюс функция подсветки поворота.",
          media: {
            picture: require("@/assets/img/cars/aura/media/6.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-5.5vw",
            left: "9vw",
          },
          offsetMobile: {
            top: "-15",
            left: "-6",
          },
          title: "Aura оснащена комплексом электронных систем безопасности",
          desc: "Который обеспечивает уверенное движение в любых условиях.",
          media: {
            picture: require("@/assets/img/cars/aura/media/9.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-1vw",
            left: "18vw",
          },
          offsetMobile: {
            top: "-15",
            left: "-6",
          },
          title:
            "Кузов спроектирован с учетом повышенных требований к жесткости",
          desc: "",
          media: {
            picture: require("@/assets/img/cars/aura/media/10.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
      ],
    },
    {
      slideIndex: 80, // 70
      items: [
        {
          offset: {
            top: "-4vw",
            left: "-14vw",
          },
          offsetMobile: {
            top: "-15",
            left: "-6",
          },
          title: "Система мониторинга слепых зон",
          desc: "Обеспечивает безопасное маневрирование в потоке.",
          media: {
            picture: require("@/assets/img/cars/aura/media/5.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-1vw",
            left: "2vw",
          },
          offsetMobile: {
            top: "-15",
            left: "-6",
          },
          title: "Двухцветная окраска кузова",
          desc: "Отличает автомобиль в потоке, подчеркивает динамичность дизайна.",
          media: {
            picture: require("@/assets/img/cars/aura/media/7.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "8vw",
            left: "-8vw",
          },
          offsetMobile: {
            top: "-15",
            left: "-6",
          },
          title: "Высокий дорожный просвет (176 мм)",
          desc: "Для уверенного передвижения по любым дорогам.",
          media: {
            picture: require("@/assets/img/cars/aura/media/8.webp"),
          },
          gtmReachGoal: "aura-cross-exterior-pin-frontbumper",
        },
      ],
    },
    {
      slideIndex: 200, // 190
      items: [],
    },
  ],
};
